import { type AosNativeInteractor } from './aosNativeInteractor'
import { type IosNativeInteractor } from './iosNativeInteractor'

declare global {
  interface Window {
    opera: any
    MSStream: any
  }
}

export declare interface showShareStudyingStatsModalPayload {
  nickname: string
  period: string
  studyTime: string
  contents: Array<{
    title: string
    studyTime: string
    thumbnail: string
  }>
}

export declare interface airbridgePayload {
  eventCategory: 'airbridge.rate' | 'form.complete'
}

export declare interface amplitudeUpdateDdaySettingsEventProperty {
  dday_title: string
  dday_date: string
}

export declare interface amplitudeUpdateDdaySettingsUserProperty {
  last_updated_dday_title: string
}

export declare interface amplitudeUpdateGoalTimeEventProperty {
  previous_goal_time: string
  updated_goal_time: string
}

export declare interface amplitudeUpdateGoalTimeUserProperty {
  last_goal_time: string
}

export interface INativeInteractor {
  getNativeToken: () => Promise<string | undefined>
  showStoreProduct: (pdfId: string) => void
  showSettingModal: () => void
  showShareStudyingStatsModal: (payload: showShareStudyingStatsModalPayload) => void
  showSconnChargeModal: () => void
  showHoldPointPage: () => void
  showCouponPage: () => void
  showServiceList: () => void
  serviceLogout: () => void
  openUrl: (url: string) => void
  requestParentConsent: () => Promise<string | undefined>
  airbridgeRate: (payload: airbridgePayload) => void
  airbridgeFormComplete: (payload: airbridgePayload) => void
  amplitudeViewSurveyParticipation: () => void
  amplitudeClickSurveyParticipation: (surveyParticipation: boolean) => void
  amplitudeCompleteSurveyParticipation: () => void
  amplitudeUpdateDdaySettings: (
    eventProperty: amplitudeUpdateDdaySettingsEventProperty,
    userProperty: amplitudeUpdateDdaySettingsUserProperty,
  ) => void
  amplitudeUpdateGoalTime: (
    eventProperty: amplitudeUpdateGoalTimeEventProperty,
    userProperty: amplitudeUpdateGoalTimeUserProperty,
  ) => void
  amplitudeClickShareAchievement: () => void
}

export class NativeInteractor {
  constructor(
    private readonly iosInteractor: IosNativeInteractor,
    private readonly aosInteractor: AosNativeInteractor,
  ) {}

  async getNativeToken() {
    const device = this.getMobileOS()

    if (device === 'iOS') {
      console.log('device iOS')
      return await this.iosInteractor.getNativeToken()
    } else if (device === 'Android') {
      console.log('device AOS')
      return await this.aosInteractor.getNativeToken()
    } else {
      throw new Error(`Unknown device: ${device}`)
    }
  }

  async requestParentConsent() {
    const device = this.getMobileOS()

    if (device === 'iOS') {
      console.log('device iOS')
      return await this.iosInteractor.requestParentConsent()
    } else if (device === 'Android') {
      console.log('device AOS')
      return await this.aosInteractor.requestParentConsent()
    } else {
      throw new Error(`Unknown device: ${device}`)
    }
  }

  showStoreProduct(pdfId: string) {
    this.applyToAllInteractors((bridge) => {
      bridge.showStoreProduct(pdfId)
    })
  }

  showSettingModal() {
    this.applyToAllInteractors((bridge) => {
      bridge.showSettingModal()
    })
  }

  showShareStudyingStatsModal(payload: showShareStudyingStatsModalPayload) {
    this.applyToAllInteractors((bridge) => {
      bridge.showShareStudyingStatsModal(payload)
    })
  }

  showSconnChargeModal() {
    this.applyToAllInteractors((bridge) => {
      bridge.showSconnChargeModal()
    })
  }

  showHoldPointPage() {
    this.applyToAllInteractors((bridge) => {
      bridge.showHoldPointPage()
    })
  }

  showCouponPage() {
    this.applyToAllInteractors((bridge) => {
      bridge.showCouponPage()
    })
  }

  showServiceList() {
    this.applyToAllInteractors((bridge) => {
      bridge.showServiceList()
    })
  }

  serviceLogout() {
    this.applyToAllInteractors((bridge) => {
      bridge.serviceLogout()
    })
  }

  openUrl(url: string) {
    this.applyToAllInteractors((bridge) => {
      bridge.openUrl(url)
    })
  }

  airbridgeRate(payload: airbridgePayload) {
    this.applyToAllInteractors((bridge) => {
      bridge.airbridgeRate(payload)
    })
  }

  airbridgeFormComplete(payload: airbridgePayload) {
    this.applyToAllInteractors((bridge) => {
      bridge.airbridgeFormComplete(payload)
    })
  }

  amplitudeViewSurveyParticipation() {
    this.applyToAllInteractors((bridge) => {
      bridge.amplitudeViewSurveyParticipation()
    })
  }

  amplitudeClickSurveyParticipation(surveyParticipation: boolean) {
    this.applyToAllInteractors((bridge) => {
      bridge.amplitudeClickSurveyParticipation(surveyParticipation)
    })
  }

  amplitudeCompleteSurveyParticipation() {
    this.applyToAllInteractors((bridge) => {
      bridge.amplitudeCompleteSurveyParticipation()
    })
  }

  amplitudeUpdateDdaySettings(
    eventProperty: amplitudeUpdateDdaySettingsEventProperty,
    userProperty: amplitudeUpdateDdaySettingsUserProperty,
  ) {
    this.applyToAllInteractors((bridge) => {
      bridge.amplitudeUpdateDdaySettings(eventProperty, userProperty)
    })
  }

  amplitudeUpdateGoalTime(
    eventProperty: amplitudeUpdateGoalTimeEventProperty,
    userProperty: amplitudeUpdateGoalTimeUserProperty,
  ) {
    this.applyToAllInteractors((bridge) => {
      bridge.amplitudeUpdateGoalTime(eventProperty, userProperty)
    })
  }

  amplitudeClickShareAchievement() {
    this.applyToAllInteractors((bridge) => {
      bridge.amplitudeClickShareAchievement()
    })
  }

  private applyToAllInteractors(lambda: (bridge: INativeInteractor) => void) {
    lambda(this.iosInteractor)
    lambda(this.aosInteractor)
  }

  private getMobileOS() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone'
    }

    if (/android/i.test(userAgent)) {
      return 'Android'
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS'
    }

    return 'unknown'
  }
}
