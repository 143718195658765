import { setupIonicReact, IonApp, IonContent } from '@ionic/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React, {
  type RefObject,
  Suspense,
  createContext,
  lazy,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { useCookies } from 'react-cookie'
import { ErrorBoundary } from 'react-error-boundary'
import { Outlet, useLocation } from 'react-router-dom'

import { ErrorFallbackFullScreen } from './components/common/ErrorFallback'
import { LoadingFallbackFullScreen } from './components/common/LoadingFallback'
import { useIsOnlineData, useMyPage } from './module/myPage/hook'
import { useNativeInteractor } from './module/nativeInteractor/context'
import { useSconnStudyDataApi } from './module/sconnStudyData/context'
import { useNetworkStatus } from './util/hooks/useNetworkStatus'

import './components/common/DateTimePicker.css'
import './components/common/Modal.css'

setupIonicReact()

const queryClient = new QueryClient()

const UserInformation = lazy(async () => await import('./pages/UserInformation'))

const ContentRefContext = createContext<RefObject<HTMLIonContentElement> | null>(null)
export const useContentRef = () => useContext(ContentRefContext)

function App() {
  const location = useLocation()
  const isOnline = useNetworkStatus()
  const { setIsOnline } = useIsOnlineData()
  const { interactor } = useNativeInteractor()
  const { sconnStudyData } = useSconnStudyDataApi()
  const contentRef = useRef<HTMLIonContentElement>(null)
  const [cookies] = useCookies(['exp'])

  useEffect(() => {
    interactor
      ?.getNativeToken()
      .then((accessToken: string | undefined) => {
        if (accessToken) {
          sconnStudyData.setAccessToken(accessToken)
          sconnStudyData.setExpires(cookies.exp)
        }
      })
      .catch((e: any) => {
        console.log('callNativeFunction, error', e)
      })
  }, [])

  useEffect(() => {
    contentRef.current?.scrollToTop(300)
  }, [location])

  useEffect(() => {
    setIsOnline(isOnline)
  }, [isOnline])

  return (
    <IonApp>
      <IonContent ref={contentRef}>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary FallbackComponent={ErrorFallbackFullScreen}>
            <Suspense fallback={<LoadingFallbackFullScreen />}>
              <UserInformation />
            </Suspense>
          </ErrorBoundary>
          <ContentRefContext.Provider value={contentRef}>
            <Outlet />
          </ContentRefContext.Provider>
        </QueryClientProvider>
      </IonContent>
    </IonApp>
  )
}

export default App
