import { type AxiosError } from 'axios'
import React, { lazy, Suspense, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { type UpdateResponse } from '../api/interfaces/sconnStudyData'
import '../components/common/Picker.css'
import { useMyPage } from '../module/myPage/hook'
import { calculateProgress, type TodayStudyProgress } from '../module/myPage/statsHelper'
import { useNativeInteractor } from '../module/nativeInteractor/context'
import { useUpdateCurrentGoalOfDays, useUpdateNextGoalOfDays } from '../module/sconnStudyData/hook'
import { useErrorToast } from '../util/hooks/useToast'
import { secondsToHour, secondsToMinute } from '../util/numberUtils'

import { ErrorFallbackSM } from './common/ErrorFallback'
import EditGoalAlertModal from './EditGoalAlertModal'
import GoalOfDaysEditModal from './GoalOfDaysEditModal'
import { formatDuration, GoalOfDaysSkeleton } from './GoalOfDaysLayout'

const GoalOfDaysLayout = lazy(async () => import('./GoalOfDaysLayout'))

export default function GoalOfDays() {
  const { interactor } = useNativeInteractor()

  const { mutate: mutateNextGoal, isPending: isNextGoalPending } = useUpdateNextGoalOfDays()
  const { mutate: mutateCurrentGoal, isPending: isCurrentGoalPending } =
    useUpdateCurrentGoalOfDays()

  const { surveyParticipated } = useMyPage()
  const { setOpenError, setErrorMessage, errorToast } = useErrorToast()
  const [openPopover, setOpenPopover] = useState<boolean>(false)
  const [openEdit, setOpenEdit] = useState<boolean>(false)
  const [openAlert, setOpenAlert] = useState<boolean>(false)
  const [isGoalAchieved, setIsGoalAchieved] = useState<boolean>(false)
  const [todayStudyProgress, setTodayStudyProgress] = useState<TodayStudyProgress>({
    duration: 0,
    goalDuration: 0,
    continuousDays: 0,
  })

  const handleMutateSuccess = (data: UpdateResponse, variables: number) => {
    if (data.result === 'Success') {
      interactor.amplitudeUpdateGoalTime(
        {
          previous_goal_time:
            todayStudyProgress.goalDuration === 0
              ? '0시간'
              : formatDuration(todayStudyProgress.goalDuration),
          updated_goal_time: formatDuration(variables),
        },
        { last_goal_time: formatDuration(variables) },
      )

      setOpenEdit(false)
      setOpenAlert(isGoalAchieved)
    }
  }

  const handleMutateError = (err: AxiosError) => {
    setOpenError(true)
    setErrorMessage(`목표 설정에 실패했습니다. [${err.message}]`)
  }

  return (
    <>
      {surveyParticipated && (
        <div className='flex flex-col mx-[20px] mt-[14px] tablet-md:mx-[42px] tablet-lg:mx-[100px] gap-[13px]'>
          <ErrorBoundary FallbackComponent={ErrorFallbackSM}>
            <Suspense fallback={<GoalOfDaysSkeleton />}>
              <GoalOfDaysLayout
                data={todayStudyProgress}
                openPopover={openPopover}
                isOverDuration={isGoalAchieved}
                onLoadData={(data) => {
                  const { progress, isGoalAchieved } = calculateProgress(data)
                  setTodayStudyProgress(progress)
                  setIsGoalAchieved(isGoalAchieved)
                  setOpenPopover(progress.goalDuration === 0)
                }}
                onClickEdit={() => {
                  setOpenEdit(true)
                }}
                onClickClosePopover={() => {
                  setOpenPopover(false)
                }}
              />
            </Suspense>
          </ErrorBoundary>
          <GoalOfDaysEditModal
            open={openEdit}
            hours={secondsToHour(todayStudyProgress.goalDuration)}
            minutes={
              todayStudyProgress.goalDuration === 0
                ? 10
                : secondsToMinute(todayStudyProgress.goalDuration)
            }
            onCancel={() => {
              setOpenEdit(false)
            }}
            loading={isNextGoalPending || isCurrentGoalPending ? 'true' : 'false'}
            onOk={(seconds) => {
              if (todayStudyProgress.goalDuration === seconds) {
                setOpenEdit(false)
                return
              }

              const options = {
                onSuccess: handleMutateSuccess,
                onError: handleMutateError,
              }

              if (isGoalAchieved) {
                mutateNextGoal(seconds, options) // post
              } else {
                mutateCurrentGoal(seconds, {
                  // put
                  ...options,
                  onError: (err) => {
                    if (err.message === 'EXIST_NEXT_GOAL') {
                      mutateNextGoal(seconds, {
                        // post
                        onSuccess: handleMutateSuccess,
                        onError: handleMutateError,
                      })

                      setOpenEdit(false)
                      setOpenAlert(isGoalAchieved)
                    } else {
                      setOpenError(true)
                      setErrorMessage(`목표 설정에 실패했습니다. [${err.message}]`)
                    }
                  },
                })
              }
            }}
          />
          <EditGoalAlertModal
            open={openAlert}
            onClickOk={() => {
              setOpenAlert(false)
            }}
          />
          {errorToast()}
        </div>
      )}
    </>
  )
}
